import UserSessionTimeout, {getUserSessionExpires} from "./timeout";
import getCoreConfig from "../config"

const $ = window.$ ;

const initSessionTimeout = () => {
  let hasDisplayedSessionTimeoutWarning = false
  let expiresAt = getUserSessionExpires()
  const config = getCoreConfig()

  const $modal = $('#session-timeout-modal');
  if (!$modal.length || !config || !config.is_authenticated) {
    return
  }
  const modelEl = $modal[0]


  if (expiresAt) {
    const timeoutCountdown = new UserSessionTimeout(expiresAt);
    var $focused;

    timeoutCountdown.on('update', (countdown, evtName) => {
      let remaining = countdown.secondsRemaining();
      console.log(`timeout seconds remaining: ${remaining}`)
      if (remaining < 60 && hasDisplayedSessionTimeoutWarning === false) {
        hasDisplayedSessionTimeoutWarning = true
        $focused = $(document.activeElement);
        $modal.modal({
          show: true,
          keyboard: false,
          backdrop: 'static'
        });
      }
    })

    timeoutCountdown.on('complete', (countdown, evtName) => {
      setTimeout(() => document.location.href = "/?sto=1", 2000)
    })

    modelEl.querySelector('button[name="reset-session-expiry"]').addEventListener('click', e => {

      if (timeoutCountdown.secondsRemaining()) {
        fetch(config.auth_ping_url).then(response => {
          hasDisplayedSessionTimeoutWarning = false
          timeoutCountdown.expiresAt = getUserSessionExpires()
          $modal.modal('hide');
        }).catch(error => console.log(error))
      } else {
        fetch(config.auth_ping_url).then(response => {
          document.location.href = "/?sto=1"
        }).catch(error => console.log(error))
      }
    });
  }
  $modal.on("shown.bs.modal", function() { 
    $('#header, #footer, #main, .accessibility-links__skip-to-main').attr('aria-hidden', 'true');
  })
  $modal.on("hidden.bs.modal", function() { 
    $focused.focus() 
    $('#header, #footer, #main, .accessibility-links__skip-to-main').removeAttr('aria-hidden');
  })  
}


initSessionTimeout()

if (window.location.search.includes('sto=1')) {
  const el = $('#session-ended-modal')
  el.modal({
    show: true,
    keyboard: false,
    backdrop: 'static'
  });
  $('#header, #footer, #main, .accessibility-links__skip-to-main').attr('aria-hidden', 'true');

  el.on("hidden.bs.modal", function() { 
    $('#header, #footer, #main, .accessibility-links__skip-to-main').removeAttr('aria-hidden');
  })   
}
