export default () => {
  let configEl = document.getElementById('core-ui-config')
  let config = null
  if (configEl) {
    config = JSON.parse(configEl.innerHTML)
  }
  return config
}


